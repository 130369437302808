@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override specific base styles */
@layer base {
  h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dd, hr, figure {
    margin: revert; /* Reset to the browser's default style */
  }

  /* If you want to disable specific styles entirely, you can set them to initial values */
  h1, h2, h3, h4, h5, h6 {
    font-size: initial;
    font-weight: initial;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
