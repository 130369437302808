html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* overflow: hidden; */
  background: #000;
  color: #fff;
  /* background-image: url("./assets/img/bg-black.jpg");  */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

* {
  box-sizing: border-box;
}


  h1, h2, h3, h4, h5, h6 {
      font-family: 'monserrat', sans-serif; /* Example for applying REM to headers */
  }

  h2 {
    font-weight: 800;
  }
  
  .app {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    z-index: 1000;
    color: white;
  }

  .header .logo {
    width: 85px;
    padding-left: 13px;
    padding-top: 10px;
    pointer-events: auto;
  }

  .header .search {
    padding-top: 3%;
    padding-right: 2%;
    font-size: 1.4em;
  }

  /* src/assets/css/style.css */
    .header .search {
      position: relative;
    }

    .header .search-input {
      position: absolute;
      top: 15px;
      right: 0;
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0;
      border-radius: 5px;
      width: 0px;
      height: 27px;
      flex-direction: row;
      justify-content: space-evenly;
      overflow: hidden;
      align-content: stretch;
      flex-wrap: nowrap;
      transition: width 0.3s ease-in-out;
      visibility: hidden; /* Make visible when showing */
      
    }

    .search-input.show {
      width: 200px; /* Target width */
      padding: 5px;
      border: 1px solid white;
      visibility: visible;
      height: 30px;
    }

    .header .search-input input {
      border: none;
      outline: none;
      /* padding: 5px; */
      height: 100%;
      color: #e5e5e5;
      height: 14px;
      background: transparent;
      font-size: 12px;
      
    }

    .header .search-input button {
      margin-left: 20px;
      padding: 0px 0px 0px;
      background-color: transparent;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 30px;
      font-size: 18px;
    }

    .header .search-input button:hover {
      background-color: #555;
    }




  .navbar-desktop {
    display: none;
  }

  .right-sidebar {
    display: none;
  }
  
  .sidebar {
    position: fixed;
    right: 5%;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    width: 9%;
    text-align: center;
    gap: 4px
  }

    .sidebar.type2 {
      
      right: 6%;
      bottom: 10%;
      position: fixed;
    }

    .team-sidebar {
      bottom: 10% !important;
    }

  .sidebar-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    text-decoration: none;
  }
  
  .sidebar-button img {
    width: 25px;
    height: auto;
  }
  
  .sidebar-button span {
    margin-top: 0px;
    font-size: 10px;
    color: #fff;
  }
  
  
  .scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  
  .scroll-item {
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .current {
    transition: outline 0.3s ease;
  }

  
  
  .fullscreen {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
  }
  
  .fullscreen-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content-info {
    position: absolute;
    bottom: 10%;
    left: 30px;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    width: 75%;
    z-index: 5;
  }

  /* .content-info h2 {
   font-size: 1em;
  } */

  .content-title {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .content-text {

  }
  
  
  .content-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure the gradient doesn't overflow the container */
  }

  .content-detail-info-info {
    position: relative;
  }
    .content-detail-info::after {
      content: " ";
      width: 100%;
      height: 20vh;
      position: fixed;
      left: 0;
      bottom: 0;
      background: linear-gradient(0deg, black 50%, transparent 100%);
    }

    .content-detail-info p {
      font-size: 0.8rem;
    }

.content-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  pointer-events: none;
  z-index: 1;
}

  .single-media {
    height: 100%;
    width: 100%;
  }

  /*carausel*/

  .carousel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .carousel img.fullscreen-media,
  .carousel video.fullscreen-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
  }
  
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .carousel-item {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
  }
  
  .carousel-control {
    width: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.1); */
    border: none;
    color: white;
    font-size: 1.7em;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 10px;
  }
  
  .carousel-dots {
    bottom: 10%;
    display: flex;
    left: 7%;
    position: absolute;
    z-index: 5;
  }
  
  .carousel-dots .dot {
    height: 3px;
    width: 7px;
    margin: 0 4px;
    background-color: rgba(255, 255, 255, 0.5);
    /* border-radius: 50%; */
    display: inline-block;
    cursor: pointer;
    z-index: 5;
  }
  
  .carousel-dots .dot.active {
    background-color: white;
  }

  .carousel.for__detail-content {
    overflow-y:unset;
  }

  .carousel.for__detail-content .carousel-item {
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 50px;
  }

  .carousel.for__detail-content .carousel-item p {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    color: #ffffff;
    font-size: 0.6rem;
    text-shadow: 1px 2px 2px black;
  }

  .carousel.for__detail-content .carousel-dots {
    display: none;
  }

  /*carousel 2 */
  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100);
  }
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .carousel-image {
    
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  
  .carousel-text {
    position: absolute;
    bottom: 13%;
    left: 7%;
    color: #fff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }

  .carousel-text h2 {
    font-size: 1.2rem;
    line-height: 1;
    padding-right: 15%;
  }

  .carousel-text a {
    font-size: 0.8rem;
    text-decoration: underline;
  }
  
  
  .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 10px;
  }


  .padding-content-detail {
    padding: 10px 65px 10px 6%;
  }

  .page-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

      .page-item:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        pointer-events: none;
        z-index: 1;
      }

  .team-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

      .team-item:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(8, 8, 8, 0.6));
        pointer-events: none;
        z-index: 1;
      }

      .team-page .carousel-item {
        position: relative;
      }
      
      .team-page .carousel-item:before {
        display: none;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(207, 42, 42));
        pointer-events: none;
        z-index: 1; /* Ensure the gradient is below the text */
      }
      
      .team-page .carousel-text {
        position: absolute;
        bottom: 50%;
        left: 7%;
        transform: translate(0%, 50%);
        z-index: 2;
        color: white;
      }

      .team-text-cont {
        width: 100%;
        height: 65px;
        position: absolute;
        bottom: 15%;
        background: linear-gradient(90deg, #95201c 0%, transparent 66%);
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      .team-text-cont.active {
        opacity: 1; /* Fully visible when active */
      }

  .team-info {
    position: absolute;
    bottom: 6%;
    left: 0px;
    padding-left: 0px;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 5;
    margin-bottom: 45px;
    height: 155px;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-start;
  }

  .team-info .team-text-cont {
    padding-left: 30px;
    padding-top: 8px;
  }

  .team-info a {
    color: white;
    padding-left: 30px;
  }

  .team-info {
    position: absolute;
    bottom: 6%;
    left: 0px;
    padding-left: 0px;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 5;
    margin-bottom: 45px;
  }

  .page-info {
    position: absolute;
    bottom: 6%;
    left: 30px;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
    width: 75%;
    z-index: 5;
    margin-bottom: 45px;
  }

  .page-info h2 {
    font-family: 'monserrat', arial;
    font-size: 1.4rem;
  }

  .team-info h2 {
    font-size: 1.6rem;
  }
  
  .page-info p {
    width: 90%;
    font-size: 12px;
  }

  .page-info a {
    font-size: 12px;
    text-decoration: underline;
  }
  

  .team-info a {
    font-size: 13px;
    text-decoration: underline;
  }

  /* navbar */
  .navbar {
    position: fixed;
    z-index: 60;
    left: 50%;
    bottom: 15px;
    width: 87%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    justify-content: space-evenly;
    padding: 12px 10px;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    align-items: center;
    transform: translate(-50%, 0);
  }
  
  .navbar-links {
    display: flex;
    gap: 40px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
  }
  
  .navbar-menu-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 18px;
    margin-left: 30px;
  }
  
  .navbar-popup-overlay {
    position: fixed;  /* Full-screen coverage */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);  /* Semi-transparent background */
    z-index: 1000;  /* High enough to cover other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-popup {
    background-color: rgb(20, 20, 20);
    color: white;
    padding: 20px 10px 10px;
    border: 1px solid #5a5a5a;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1001;  /* Above the overlay */
    max-width: 400px;
    width: 85%;
    text-align: center;
    border-radius: 10px;
  }
  
  
  .navbar-popup ul {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
  }
  
  .navbar-popup li {
    margin-bottom: 10px;
  }
  
  .navbar-popup a {
    text-decoration: none;
  }
  
  .navbar-popup a:hover {
    /* text-decoration: underline; */
    color: #d73731;
  }

  /* CSS for active link */
  .navbar-links .active,
  .navbar-popup .active,
  .navbar-desktop-links .active {
    font-weight: 900; /* Optional: make the active link bold */
    color: #d73731;
    font-family: 'REM';
  }


  .yt-cont {
    height: 25vh;
    padding: 20px;
  }

  .client-tag {
    width: fit-content;
    height: 20px;
    background: rgb(171 171 171 / 45%);
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; /* Center items vertically */
    padding: 0 0px; /* Optional: Add padding for spacing */
    margin-bottom: 7px;
  }

  .client-logo {
    width: 37px;
  }
  .client-logo img {
    border-radius: 4px;
  }

  .client-tag span {
    padding: 10px;
    font-size: 10px;
  }

  /* team detail */
  .team-info-card {
    border-radius: 0px 20px 20px 0px;
    width: 95%;
    padding: 5%;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    gap: 15px;
  }

  .team-info-card .img-cont {
    width: 180px;
    height: 180px;
    border-radius: 15px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .detail-team-page h2 {
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1;
    font-family: 'REM';
  }

  .team-job-title {
    font-family: 'Montserrat';
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1;
  }

  .detail-team-page p {
    font-size: 0.75rem;
  }


  /* SEARCH PAGE */
.search-page {
  padding: 22% 20px;
}

.search-page .search-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.search-page .search-item h2 {
  font-size: 1em;
  margin-bottom: 5px;
  white-space: nowrap;        /* Prevents text from wrapping to the next line */
  overflow: hidden;           /* Ensures that overflowing text is hidden */
  text-overflow: ellipsis;
}

.search-page .search-item p {
  font-size: 12px;
  color: #e7e7e7;
  white-space: nowrap;        /* Prevents text from wrapping to the next line */
  overflow: hidden;           /* Ensures that overflowing text is hidden */
  text-overflow: ellipsis;
}

.search-page .search-item a {
  font-size: 0.7rem;
  color: #d1d1d1;
  text-decoration: underline;
}

.search-page .no-results {
  text-align: center;
}

.search-page .no-results p {
  font-size: 1.2em;
  color: #666;
}

.search-page .no-results a {
  display: inline-block;
  margin-top: 10px;
  color: #ec3050;
  text-decoration: none;
  font-weight: bold;
}


  .page-container {
    padding-bottom: 20px;
    width: 100%;
  }

  .page-container.for__contact {
    padding-top: 20%;
    background-image: url("./assets/img/bg-red.jpg"); 
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-right: 20%;
    padding-left: 20px;
  }

  .page-container.contact-page {
    position: relative;
    height: 100vh;
  }
  .video-fullscreen-cont::before {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background: linear-gradient(0deg, black 10%, transparent 100%);
  }

  .video-fullscreen-cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .video-fullscreen-cont video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .social-media-cont {
    position: absolute;
    bottom: calc(0vh + env(safe-area-inset-bottom)); /* 10px gap from the bottom */
    transform: translateY(-10px);
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 25px;
    box-sizing: border-box;
    height: 35vh;
    overflow-y: scroll;
  }

  @media (min-height: 844px) {
    .social-media-cont {
      bottom: 2%;
      height: 25vh;
    }
  }

  .content-page {
    padding: 7% 25px;
    margin-bottom: 2.8em;
    position: relative;
  }
  .content-page::after {
    content: "";
    width: 100%;
    height: 20vh;
    position: fixed;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, black 50%, transparent 100%);
  }

  .page-container .headbanner {
    width: 100%;
    height: 300px;
    position: relative;
  }

  .page-container.for__contact .headbanner {
    width: 300px;
    height: 300px;
    position: relative;
  }

  .contact-card {
    border: 1px solid white;
    border-radius: 10px;
    overflow: hidden;
    
  }

  .social-media-cont .social-media{
    display: flex;
    justify-content: center;
  }

  .social-media-cont .social-media .socmed-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 6px; /* Adjust spacing between items */
    list-style: none;
    margin: 0;
    max-width: 400px; /* Adjust max-width as needed */
  }

  .social-media-cont .social-media .socmed-cont li{
    flex: 0 1 calc(50% - 10px); /* 2 columns */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .social-media-cont .social-media a{
    font-size: 0.85rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px; /* Spacing between icon and text */
  }

  .social-media-cont .social-media a:hover {
    color:#d73731;
  }

  .social-media-cont .address-cont {
    color: white;
    font-size: 0.7rem;
  }

  .social-media-cont .address-text {
    color: white;
    font-size: 0.7rem;
  }

  .page-container.for__contact .headbanner img {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .page-container .headbanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-position: center; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

  }

    .page-container h2 {
      font-size: 1.7rem;
      font-weight: 800;
      font-family: 'REM';
    }

  .tags-list {
    padding:10px 0px;
  }

    .tags-list li {
      display: inline-block;
      background-color: white;
      color: black;
      padding: 2px 8px;
      margin: 0px 2px;
      font-size: 0.6rem;
      border-radius: 20px;
    }
  

    .social-media a {
      margin:0px 5px;
      font-size: 1.5rem;
    }

    .award-detail-page {
      padding-left: 25px;
      padding-right: 25px;
    }

    .award-detail-page h2{
      font-size: 1.4rem;
      line-height: 1.2;
  
    }
    
    .youtube-section {
      margin-bottom: 20px;
      height: 25vh;
    }

    .image-section {
      width: 100%;
      height: 200px;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
    }

      .image-section img{
        width: 100%;
        height: 180%;
        object-fit: cover;
        position: absolute;
      }
    
    .client-tag {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    
    .client-logo img {
      width: 50px;
      height: auto;
      margin-right: 10px;
    }
    
    .content-title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    
    .medals-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 90%;
      overflow-y: scroll;
    }
    
    .medal-item {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid grey;
      padding: 10px 0px;
    }
    
    .medal-item img {
      width: 50px;
      height: auto;
    }

    .medal-item p{
      font-size: 0.8rem;
    }

    /* .map-cont iframe {
      margin-bottom: 10px;
    } */
    .address-cont h3 {
      font-family: 'REM';
      margin-bottom: 10px;
    }

    .address-cont ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 0.8rem;
      gap: 20px;
    }


    /* .map-cont iframe{
      width: 100%;
      height: 25vh;
    } */

    .address-cont p {
      font-size: 0.8rem;
    }

  .award-list-layout {
    height: 100vh;
  }

  .awards-carousel .awards-dots {
    display: none;
  }

  .award-list-layout .content-item {
    
  }

  .award-list-layout .content-item:before {
    display: none;
  }

  .award-list-layout .carousel-item {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    padding: 20% 30px;
  } 

  .award-list-layout h2 {
    font-size: 2rem;
  }

  .award-list-layout .medal-item p{
    font-size: 0.7rem;
  } 

  /*client page*/
  .client-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    overflow-x: hidden;
  }
  
  .client-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Force 2 columns per row */
    gap: 20px;
    width: 100%;
    align-items: center;
    max-height: 400px; /* Set the height limit */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .client-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .client-box-cont {
    background: white;
    min-height: 200px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    display: grid; /* Use grid here for full control */
    grid-template-columns: repeat(2, 1fr); /* Enforce 2 columns */
    gap: 10px;
    align-items: center;
    justify-items: center;
    max-height: 70vh; /* Adjust the height if necessary */
    overflow-y: auto; /* Enable scrolling */
  }
  
  .client-list-cont {
    max-width: 100px;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .client-item-img {
    max-height: 60px;
    vertical-align: center;
  }
  
  .client-image {
    width: 100%;
    height: auto;
    max-height: 80px; /* Ensure consistent height */
    object-fit: contain;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .client-row {
      grid-template-columns: repeat(1, 1fr); /* One item per row on smaller screens */
    }
  
    .client-box-cont {
      grid-template-columns: repeat(1, 1fr); /* Force single column on mobile */
      max-height: none; /* Remove height constraint on mobile */
    }
  }
  
  /* Larger screen adjustments */
  @media (min-width: 1024px) {
    .client-image {
      max-height: 90px; /* Increase size for larger screens */
    }
  }
  
  .partner-cont {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    /* background-color: #000; */
    color: #fff;
    gap: 10px;
    border-top: 1px solid #464646;
    align-items: center;
    align-content: flex-start;
    flex-direction: row;
  }
  
  .partner-text {
    flex: 1 1;
    max-width: 60%;
    font-size: 8px;
    line-height: 1;
  }
  
  .partner-logos {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .partner-logos img {
    border: 1px solid #464646;
    border-radius: 6px;
    padding: 4px;
  }

  .partner-logos a {
    opacity: 0.6;
  }

  .partner-logos a:hover {
    opacity: 1;
  }
  
  .partner-logo {
    max-width: 50px;
    height: auto;
  }
  
  .dl-credential {
    text-decoration: underline;
    font-size: 0.7rem;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: black;
    padding: 40px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .modal-content input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    border-radius: 6px;
    color: black;
  }


.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}
  
  .modal-content .submit-btn {
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 12px;
    color: black;
  }
  
  
  



  :root {
    --vh: 100vh; /* Fallback for older browsers */
  }
  
  .app, .scroll-container, .fullscreen, .scroll-item {
    height: calc(var(--vh, 1vh) * 100);
  }
  
  