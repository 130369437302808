/* Tablet Styles */
@media (min-width: 1025px) {
    body {
      background: black;
    }

    .header {
      pointer-events: none;
      position: fixed;
      top: 0;
      left: 20px;
      width: 30%;
      padding: 10px;
      z-index: 1000;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 55px;
    }
    .header .search {
      width: 60%;
      padding-top:1%;
      pointer-events: auto;
    }

    .header .search .fa.fa-search {
      position: absolute;
      right: 8%;
      top: 55%;
      transform: translate(0%, -50%);
    }

    .header .search input {
      background: none;
      border: 1px solid #c3c3c3;
      border-radius: 8px;
      padding: 8px 8px;
      font-size: 14px;
      width: 100%;
    }

    .header .logo {
      width: 10em;
      padding-left: 13px;
      padding-top: 20px;
      pointer-events: auto;
    }

    .search-input.show {
      width: 250px;
      padding: 10px;
      height: 45px;
      visibility: visible;
      border-radius: 9px;
      border: 2px solid white;
  }

    .latest-page {
      position: relative;
      max-width: 30%;  
      margin: 0 auto;
    }

    .pdc {
      position: relative;
      max-width: 30%;  
      margin: 0 auto; 
    }

    .content-detail-page {
      width: 30%;
      margin: 2% auto;
      position: relative;
      border-radius: 20px;
      overflow: hidden;
    }

    .content-title {
      font-size: 1rem;
    }

    .content-text {
      font-size: 0.8rem;
    }

    .content-item,
    .page-item,
    .team-item {
        height: 90%;
        border-radius: 20px;
    }
    

    .carousel-item {
      height: 100%;
      border-radius: 30px;
      /* padding-top: 31px; */
      overflow: hidden;
      /* margin-top: 10%; */
    }

    .carousel-text {
        position: absolute;
        bottom:8%;
        left: 8%;
        color: #fff;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    }

    .carousel-text h2 {
      padding: auto;
    }

    .carousel-control {
      font-size: 1.2rem;
      opacity: 0.4;
    }

    .carousel-control:hover {
      opacity: 0.9;
    }

      .carousel-control.next {
        right: 0px;
      }

      .carousel-control.prev {
        left: 0px;
    }

    .content-info {
      margin-bottom: 25px;
      bottom: 2%;
    }

    /* carousel for detail content*/
    .carousel.for__detail-content {
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      flex-direction: column;
    }

      .carousel.for__detail-content .carousel-item {
        height: 100%;
        border-radius: 0px;
        overflow: hidden;
        margin-top: 0px;
        padding-bottom: 50px;
        position: relative;
        shape-outside: margin-box;
      }

      .carousel.for__detail-content .carousel-item p {
        position: absolute;
        bottom: 20px;
        font-size: 0.6rem;
      }

      .carousel.for__detail-content .carousel-control {
        top: 40%;
      }

      .content-detail-page .carousel-dots {
        display: none;
      }

    .award-page {
      width: 30%;
      margin: 0 auto;
      position: relative;
    }
    .award-page .carousel-content {
      display: flex;
      transition: transform 0.5s ease;
      align-content: center;
      align-items: center;
      height: 95%;
      padding-top: 9%;
    }
    .award-page .carousel-item {
      
        height: 100%;
        border-radius: 20px;
        padding: 0px;
        overflow: hidden;
        margin: 0px;
    }

    .award-page .carousel-text {
      position: absolute;
      bottom: 10%;
      left: 6%;
      color: #fff;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      width: 80%;
    }

    .award-page .carousel-dots {
        display: none;
    }

    .award-list-layout .carousel-item {
      padding: 2% 20px 0px;
    }

    /*navbar*/
    .navbar {
        display: none; /* Hide the mobile navbar */
      }
    
      .navbar-desktop {
        position: fixed;
        display: flex;
        padding: 20% 20px 1% 30px;
        background-color: black;
        color: #4d4d4d;
        top: 0px;
        width: 25%;
        height: 90vh;
        align-content: flex-end;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
      }
    
      .navbar-desktop-links {
        display: flex;
        gap: 12px;
        list-style: none;
        flex-direction: column;
        margin-bottom: 20px;
      }
    
      .navbar-desktop-links a {
        
        text-decoration: none;
        font-size: 1.1rem;
        font-weight: bold;
        transition: color 0.3s;
      }
    
      .navbar-desktop-links a:hover {
        color: #d73731; 
      }

      .logo-network-cont {
        width: 200px;
      }

      .navbar-social-links {
        list-style: none;
        padding: 0px 0px 0px;
        margin: 0;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-end;
        min-height: 75px;
      }
      
      .navbar-social-links li {
        margin: 2px 0px;
        width: 50%;
      }
      
      .navbar-social-links a {
        text-decoration: none;
        color: #4d4d4d;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
      }
      
      .navbar-social-links i {
        margin-right: 8px; /* Spacing between icon and text */
      }
      
      .navbar-social-links a:hover {
        color:#d73731; /* Hover color for the icons */
      }
      

      .sidebar {
        position: absolute;
        right: -15%;
        bottom: 10%;
        width: 10%;
      }

        .sidebar-button {
          padding: 0 0 5px;
        }

        .sidebar-button img {
          width: 25px;
          height: auto;
        }

        .sidebar-button span {
          margin-top: 0px;
          font-size: 10px;
          color: #fff;
        }

      .sidebar.type2 {
        position: fixed;
        right: 50%;
        bottom: 10%;
        transform: translate(230%, 0%);
      }

      .carousel-dots {
        bottom: 2em;
      }

      .carousel-dots.team-dots {
        bottom: 12%;
      }

      .detail-team-page {
        width: 30%;
        margin: 0 auto;
        position: relative;
      }

      .team-info-card {
        gap: 20px;
        align-items: center
      }

      .team-info-card .img-cont {
        width: 140px;
        padding-top: 0px;
        position: relative;
        overflow: hidden;
        height: 140px;      
      }

        .team-info-card .img-cont img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 150%;
          object-fit: cover;
        }

        .detail-team-page h2 {
            font-family: 'monserrat', arial;
            font-size: 1rem;
            font-weight: 900;
            line-height: 1;
        }

        .team-job-title {
            font-size: 0.8rem;
            font-weight: normal;
        }

        .detail-team-page p {
            font-size: 0.8rem;
        }

      
      .page-info {
        position: absolute;
        bottom: 0%;
        left: 25px;
        color: white;
        padding: 10px;
        border-radius: 5px;
      }

      .page-award .carousel-item {
        padding: 0px 20px;
      }

      .right-sidebar {
        position: fixed;
        right: 0;
        top: 0;
        width: 25%;
        height: 95vh;
        background-color: black;
        color: #4f4f4f;
        padding: 2% 50px 3% 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        align-content: flex-start;
      }

      .single-line-ellipsis {
        display: inline-block;
        max-width: 100%;
        /* overflow: hidden;
        white-space: nowrap; */
        /* text-overflow: ellipsis; */
        vertical-align: top;
      }
      
      /* Styles for the Latest Content Section */
      .latest-content {
        margin-bottom: 10px;
        color: #4d4d4d; 
      }

      .latest-content h3 {
        margin-bottom: 10px; 
        font-size: 1.1rem;
        font-weight: 600;
      }

      .latest-content ul {
        list-style-type: decimal; /* Set list style to numbers */
        padding: 0; /* Remove default padding */
        margin: 0; /* Remove default margin */
      }

      .latest-content li {
        max-width: 100%;
        margin-left: 10px;
        margin-bottom: 10px;
        font-size: 1em;
        padding-left: 10px;
      }

      .latest-content li::marker {
        content: counter(list-item);
        /* font-weight: 800; */
        margin-right: 15px;
        color: #682222;
      }

      .latest-content a {
         /* Set link text color to white */
        text-decoration: none;
        font-family: 'monserrat', arial;
      }

      .latest-content a:hover {
        text-decoration: none;
        color: #d73731;
      }

      

      /* .map-cont iframe {
        height: 36vh;
      } */

      .social-media-cont {
          height: 17%;
          position: absolute;
          bottom: 60px;
          left: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          color: white;
          padding: 20px;
          box-sizing: border-box;
          overflow-y: scroll;
        
      }

      .social-media-cont .social-media {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
      }

    .social-media-cont .social-media a {
        font-size: 0.8rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 5px;
    }

      .contact-page .video-fullscreen-cont {
        border-radius: 20px;
      }
      
      .contact-info {
        font-family: 'monserrat', Arial, Helvetica, sans-serif;
        border-top: 1px solid #464646;
        padding: 8% 0px 0px 0px;
        font-size: 1rem;
        color: #4d4d4d;
        h4 {
          margin-bottom: 5px;
        }
      
        p {
          margin:2px 0;
        }
      }

      .page-container {
        width: 30%;
        margin: 0 auto;
        padding-top: 5%;
      }

      .page-container.for__contact {
       padding: 5% 20px;
       background-image: none;
      }

      .video-fullscreen-cont {
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        height: 85vh;
        overflow: hidden;
    }

    .content-page {
      padding: 0 0px;
      margin-bottom: 2.8em;
    }
    
      .award-detail-page {
        padding: 0px;
        width: 30%;
        margin: 0 auto;
        margin-top: 0px;
        border-radius: 20px;
        overflow: hidden;
        margin-top: 2%;
      }

      .award-list-layout h2 {
        font-size: 1.5rem;
      }

      .client-container {
        padding-top: 10%
      }

      .content-detail-info h2 {
        font-size: 1.3rem;
      }

      .content-detail-info::after {
        display: none;
      }

      .content-page::after {
        display: none;
      }
.partner-cont {
  padding: 20px 0px 0px;
}

.partner-text {
  color: #4d4d4d;
  font-size: 9px;
}
      


      .yt-cont {
        height: 35vh;
        padding: 0px;
      }

      .youtube-section {
        height: 35vh;
      }

      .search-page {
        padding: 4% 20px;
      }
      
/*comment*/

}